import React from 'react';
import Chart from '../../components/icons/chartUseSpring';
import Layout from '../../components/layout'

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
};


const Charts = () => (
<Layout>
<article
  className="page type-page status-publish hentry entry">
  <div style={styles} className="entry-content">
      <h3>ChartTest</h3>
    <Chart name="3DChart" />
  </div>
  </article>
  </Layout>
);

export default Charts;
